import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, Navigation, Thumbs } from 'swiper';
import { Link as GoLink } from 'react-scroll/modules';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import HtmlEscape from '../../../components/EscapeHtml';
import 'swiper/swiper-bundle.min.css';
import Image from '../../../components/Image';
import Mq from '../../../../utils/useMediaQuery';

SwiperCore.use([Navigation, Thumbs, EffectFade, Autoplay]);

const MorsoPage = (props) => {
  const { location } = props;
  const [itemId, setItemId] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemOrder, setItemOrder] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemText, setItemText] = useState('');
  const [itemTable, setItemTable] = useState('');
  const [galleryTitle, setGalleryTitle] = useState('');

  // Ajax設定
  const configJson = {
    method: 'get',
    url: `/update/brand/morso/${itemId}/index.json`,
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  const configText = {
    method: 'get',
    url: `/update/brand/morso/${itemId}/text.html`,
    headers: {
      Accept: 'text/html',
    },
    data: '',
  };
  const configTable = {
    method: 'get',
    url: `/update/brand/morso/${itemId}/table.html`,
    headers: {
      Accept: 'text/html',
    },
    data: '',
  };

  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
      .then((response) => {
        setItemName(response.data.title);
        setItemOrder(response.data.ordering);
        setItemDescription(response.data.description);
        setGalleryTitle(response.data.galleryTitle);
      })
      .catch(() => {
        // console.log(error);
      });
    axios(configText)
      .then((response) => {
        setItemText(response.data);
      })
      .catch(() => {
        // console.log(error);
      });
    axios(configTable)
      .then((response) => {
        setItemTable(response.data);
      })
      .catch(() => {
        // console.log(error);
      });
  };

  // ギャラリー
  const GallerySlide = (val) => {
    const { textArray } = val;
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
      <>
        <Swiper
          className="gallery-main-slider"
          loop
          speed={1000}
          slidesPerView={1}
          thumbs={{ swiper: thumbsSwiper }}
        >
          {textArray.map((text, index) => (
            <>
              <SwiperSlide>
                <img
                  className="w-full"
                  src={`/update/brand/morso/${itemId}/slider/${index + 1}.png`}
                  alt=""
                />
              </SwiperSlide>
            </>
          ))}
        </Swiper>
        <Swiper
          className="gallery-thumb-slider"
          onSwiper={setThumbsSwiper}
          slidesPerView={6}
          watchSlidesVisibility
          watchSlidesProgress
          allowTouchMove={false}
        >
          {textArray.map((text, index) => (
            <>
              <SwiperSlide>
                <img
                  className="w-full"
                  src={`/update/brand/morso/${itemId}/slider/${
                    index + 1
                  }-thumb.png`}
                  alt=""
                />
                <p className="test">{text}</p>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </>
    );
  };

  // Event
  useEffect(() => {
    setItemId(location.search.split('?id=')[1]);
    if (itemId) {
      dataFetch();
    }
    if (typeof itemId === 'undefined' || typeof itemName === 'undefined') {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, location.search.split('?id=')[1]]);

  return (
    <Layout page="brand-page morso">
      <Seo title={`MORSO ${itemName} 商品詳細`} description={itemDescription} />
      <section className="page-head">
        <ListBrand />
        <ListLink />
        <h2 className="md:flex items-end md:ml-16">
          <Image filename="logo/morso.png" width={160} />
          { Mq('sp') ? <br /> : '' }
          <span className="md:text-2xl text-lg md:pl-8 c-yellow">{itemName}</span>
          {itemOrder ? <span className="sup-text text-sm c-gray">{itemOrder}</span>: null}
        </h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <div className="content-block">
            <img
              className="w-full"
              src={`/update/brand/morso/${itemId}/main.png`}
              alt=""
            />
            {HtmlEscape(itemText)}
            <div className="mt-8">
              <div className="md:flex">
                <div className="md:w-4/12 md:pr-8">
                  <img
                    className="w-full border"
                    src={`/update/brand/morso/${itemId}/item01.png`}
                    alt=""
                  />
                </div>
                <div className="md:w-8/12 md:pl-8 md:mt-0 mt-6">
                  <img
                    className="w-full border"
                    src={`/update/brand/morso/${itemId}/item02.png`}
                    alt=""
                  />
                </div>
              </div>
              <div className="mt-8">
                <p className="c-yellow text-2xl futura">
                  {itemName} <span className="text-lg yu">詳細</span>
                </p>
                {HtmlEscape(itemTable)}
              </div>
            </div>
          </div>
          <div id="gallery" className="content-block left-end">
            <h3 className="sub-title">GALLERY</h3>
            {galleryTitle ? <GallerySlide textArray={galleryTitle} /> : null}
          </div>
          <div className="content-block">
            <Link className="btn full white" to="/brand/morso/lineup/">
              morso 商品一覧
            </Link>
            <a className="btn pdf full mt-5" href="/update/pdf/andersen.pdf" target="_blank">
              PDFカタログ ダウンロード
            </a>
          </div>
        </div>
        <div className="side-list">
        <ul className="sticky">
            <li>
              <GoLink to="about" spy smooth duration={800}>
                {itemName} SERIES
              </GoLink>
            </li>
            <li>
              <GoLink to="gallery" spy smooth duration={800}>
                GALLERY
              </GoLink>
            </li>
          </ul>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>
            <Link to="/brand/">ブランド</Link>
          </li>
          <li>
            <Link to="/brand/morso/">morso</Link>
          </li>
          <li>
            <Link to="/brand/morso/lineup/">morso商品一覧</Link>
          </li>
          <li>{itemId}</li>
        </ul>
      </section>
    </Layout>
  );
};

export default MorsoPage;
